<template>
  <div class="login-view">
    <div class="login-header">
      <v-img
        class="edudash-logo"
        :src="require('@/assets/images/logo-home.svg')"
        width="260px"
      />
    </div>

    <div class="login-body">
      <div class="wellcome">
        {{ $t('views.login.wellcome') }}
      </div>
      <div class="instructions">
        {{ $t('views.login.instructions') }}
      </div>

      <div class="form-login">
        <login-form @submited="doLogin" />
      </div>
      <div
        class="forgot-password"
        @click="forgottenPassword"
      >
        {{ $t('views.login.forgot_password') }}
      </div>

      <div class="login-footer">
        <hr>
        <div
          class="register"
          @click="goTo('registerStepOne')"
        >
          {{ $t('views.login.register_call') }}
          <span
            class="link-to-register"
          >
            {{ $t('views.login.register') }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import LoginForm from '@/components/forms/login/LoginForm.vue';

export default {
  name: 'Login',
  components: {
    LoginForm,
  },
  methods: {
    ...mapActions([
      'initLoading',
      'login',
      'fetchTeacher',
    ]),
    doLogin(params) {
      this.initLoading();
      this.login(params);
    },
    goTo(routeName) {
      this.$router.push({ name: routeName });
    },
    forgottenPassword() {
      this.goTo('PasswordRecover');
    },
  },
};
</script>
<style lang="scss">
.login-view {
  @include font-open-sans-semi-bold;
  background-color: $color-primary;
  height: 100%;
  .login-header {
    padding: 56px 0 32px;
    .edudash-logo {
      margin: 0 auto;
    }
  }
  .login-body {
    border-radius: 20px 20px 0px 0px;
    width: 100vw;
    background-color: $background-color-primary;
    padding: 32px 32px 98px;

    .wellcome {
      @include font-open-sans-semi-bold;
      text-align: center;
      font-size: $font-size-base;
      margin-bottom: 16px;
    }

    .instructions {
      @include font-monteserrat-regular;
      text-align: center;
      color: $text-color-secondary;
      font-size: $font-size-extra-small;
      margin-bottom: 32px;
    }

    .forgot-password {
      @include font-open-sans-bold;
      text-align: center;
      color: $secondary-button-color;
      font-size: $font-size-extra-small;
      padding: 16px 32px;
      margin-top: 16px;
    }
  }

  .login-footer {
    position: absolute;
    bottom: 0;
    left: 16px;
    right: 16px;

    .register {
      @include font-monteserrat-regular;
      text-align: center;
      font-size: $font-size-extra-small;
      margin: 32px 0;

      .link-to-register {
        @include font-open-sans-bold;
        color: $secondary-button-color;
      }
    }
  }

  hr {
    border: 1px solid $border-color;
  }
}
</style>
