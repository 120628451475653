<template>
  <div class="form-login pa-0">
    <edudash-input
      v-model="cpf"
      rules="required|cpf"
      prepend-icon="description"
      :name="$t('components.login_form.cpf')"
      :label="$t('components.login_form.cpf_label')"
      :mask="cpfMask"
    />

    <edudash-input
      v-model="password"
      prepend-icon="lock"
      type="password"
      rules="required"
      :name="$t('components.login_form.password')"
      :label="$t('components.login_form.password')"
    />

    <edudash-button
      :text="$t('components.login_form.login')"
      :full-width="true"
      @click="executeLogin"
    />
  </div>
</template>

<script>
import { VueMaskDirective } from 'v-mask';
import EdudashButton from '@/components/forms/EdudashButton.vue';
import EdudashInput from '@/components/forms/EdudashInput.vue';

export default {
  name: 'LoginForm',
  directives: { mask: VueMaskDirective },
  components: {
    EdudashButton,
    EdudashInput,
  },
  data() {
    return {
      cpf: '',
      password: '',
    };
  },
  computed: {
    cpfMask() {
      return '###.###.###-##';
    },
  },
  methods: {
    executeLogin() {
      const userData = { cpf: this.unmask(this.cpf), password: this.password };
      this.$emit('submited', userData);
    },
  },
};
</script>
